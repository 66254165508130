.App {
  text-align: center;
}

.retool-box {
  min-height: 100vh;
  height: 100vh;
}

#login-help {
  padding-bottom: 10px;
}

#hide-login-help-button {
  height: 2rem;
  width: 8rem;
}